export enum RouterPaths {
  LEADS = '/leads',
  SINGLE_LEAD = '/leads/:leadId',
  SETTINGS = '/settings',
  LOGIN = '/login',
  SIGNUP = '/signup',
  ONBOARDING_ONE = '/onboarding/one',
  ONBOARDING_TWO = '/onboarding/two',
  ONBOARDING_THREE = '/onboarding/three',
  ONBOARDING_AI_ONE = '/onboarding/ai/one',
  ONBOARDING_FOUR = '/onboarding/four',
  VERIFY = '/verify',
  VERIFY_CHANGE_EMAIL = '/verify-change-email',
  WELCOME = '/welcome',
  REQUEST_CHANGES = '/request-changes',
  AUTH_CALENDLY = '/auth/calendly',
  RESET_PASSWORD_REQUEST = '/reset-password-request',
  CANCEL_RESET_PASSWORD = '/cancel-reset-password',
  RESET_PASSWORD = '/reset-password',
  INTEGRATION_PATHS_HUBSPOT = '/integration-paths/hubspot',
  ALL = '*',
}
