import { ApiBaseService } from './base.api';
import {
  AuthLoginResponse,
  AuthVerifyTokenResponse,
} from './types/auth.response';

export class ApiAuthService extends ApiBaseService {
  static async sendLogin(email: string, password: string) {
    const url = new URL(`${ApiBaseService.apiUrl}/api/auth/login`);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    }).catch((e) => {
      throw new Error('Something went wrong! Please contact support!');
    });
    if (response.status === 401) {
      throw new Error('Wrong email or password!');
    }
    if (response.status !== 201) {
      throw new Error('Something went wrong! Please contact support!');
    }
    return (await response.json()) as AuthLoginResponse;
  }

  static async verifyToken(token: string) {
    const url = new URL(`${ApiBaseService.apiUrl}/api/auth/verify-token`);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      return false;
    }
    return (await response.json()) as AuthVerifyTokenResponse;
  }
}
