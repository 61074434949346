import React from 'react';
import { motion } from 'framer-motion';
import './index.scss';
import FormInput from '../../../components/FormInput';
import Button from '../../../components/Button';
import { useForm } from 'react-hook-form';
import APIService from '../../../services/api/index.api';
import { useDispatch, useSelector } from 'react-redux';
import { useUserState } from '../../../store/selectors';
import Timezone from 'timezone-enum';
import { RouterPaths } from '../../../router/paths.d';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { actionLoadOnboardingOne } from '../../../store/actions/user.actions';
import TapformLogoWithLabel from '../../../components/TapformLogoWithLabel';

interface OnboardingOneFormValues {
  firstName: string;
  lastName: string;
}

function OnboardingOnePage() {
  const { handleSubmit, control } = useForm<OnboardingOneFormValues>({
    defaultValues: {},
    reValidateMode: 'onChange',
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, userData, isLoggedIn } = useSelector(useUserState);

  const onSubmit = React.useCallback(
    async (data: OnboardingOneFormValues) => {
      if (token) {
        try {
          const timezone =
            Object.values(Timezone).find(
              (tz) =>
                tz ===
                (Intl.DateTimeFormat().resolvedOptions().timeZone as any),
            ) || Timezone['US/Central'];
          const res = await APIService.registrationsService.onboardingOne(
            data.firstName,
            data.lastName,
            timezone,
            token,
          );
          // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
          if (res && res?.data?.success) {
            dispatch(
              actionLoadOnboardingOne({
                firstName: data.firstName,
                lastName: data.lastName,
                timezone,
              }),
            );
            navigate(RouterPaths.ONBOARDING_TWO);
          }
        } catch (e: any) {
          message.error(e.message);
        }
      }
    },
    [token],
  );

  React.useEffect(() => {
    if (isLoggedIn) {
      if (userData?.onboardingStep !== 1) {
        navigate(RouterPaths.ONBOARDING_TWO);
      } else {
        window.scrollTo({ top: 0 });
      }
    } else {
      navigate(RouterPaths.ONBOARDING_TWO);
    }
  }, [userData, isLoggedIn]);

  return (
    <div className="onboarding-one-page-container">
      <TapformLogoWithLabel label="Onboarding" />
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{
          y: -100,
          opacity: 0,
          transition: { duration: 0.3, ease: 'anticipate' },
        }}
        transition={{
          delay: 0.1,
          duration: 0.55,
          ease: 'anticipate',
        }}
        className="onboarding-one-page-content"
      >
        <h1 className="onboarding-one-page-content-title">
          Hi 👋, let’s get familiar
        </h1>
        <p className="onboarding-one-page-content-description">
          We would love to get to know you better! We kindly ask you to provide
          us with your full name.
        </p>
        <form
          className="onboarding-one-page-content-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormInput
            control={control}
            type="text"
            name="firstName"
            label="First Name"
            autoComplete="given-name"
            rules={{
              maxLength: { value: 100, message: 'Must be 100 characters max' },
              required: 'Must not be empty!',
            }}
            className="onboarding-one-page-content-form-input"
            motionDelay={0.35}
          />
          <FormInput
            control={control}
            type="text"
            name="lastName"
            label="Last Name"
            autoComplete="family-name"
            rules={{
              maxLength: { value: 100, message: 'Must be 100 characters max' },
              required: 'Must not be empty!',
            }}
            className="onboarding-one-page-content-form-input"
            motionDelay={0.45}
          />
          <Button
            type="submit"
            text="Continue"
            className="onboarding-one-page-content-form-submit"
          />
        </form>
      </motion.div>
    </div>
  );
}

export default OnboardingOnePage;
